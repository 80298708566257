import config from "../config";

export const CONTACT_EMAIL = 'ask@scrambleup.com';
export const DEFAULT_LIMIT = 20;
export const DATA_FORMAT_DD_MMMM_YYYY = 'D MMMM YYYY';
export const STATIC_RELATIVE_PATH_REGEX = /^\/media\//;

export const LINKS = {
  root: '/',
  forInvestors: config.investorsPromo,
  forFounders: config.foundersPromo,
  product: `${config.investorsPromo}/product`,
  round: `${config.parentDomain}/round`,
  roundsHistory: `${config.parentDomain}/rounds-history`,
  howItWorks: `${config.investorsPromo}/how-it-works`,
  about: `${config.investorsPromo}/about`,
  contactUs: `${config.investorsPromo}/contacts`,
  help: `${config.investorsPromo}/help`,
  main: config.parentDomain,
  termsOfUse: `${config.parentDomain}/docs/Terms_of_use.pdf`,
  affiliateProgramme: `${config.parentDomain}/affiliate-programme`,
  privacyAndPolicy: `${config.parentDomain}/docs/Privacy_policy.pdf`,
  signIn: `${config.investorApp}/sign-in`,
  getStarted: `${config.investorApp}/sign-up`,
  notFound: '/404',
}