import React from "react"
import { useLocation } from "react-router-dom"

import {MyLink} from "../../../components/MyLink";
import {CONTACT_EMAIL, LINKS} from "../../../helpers/constants";

import logoBlack from "../../logoBlack.svg"
import logoWhite from "../../logoWhite.svg"
import dotWhite from "../../dotWhite.svg"
import dotBlack from "../../dotBlack.svg"
import Facebook from "../../SocialButtons/Facebook/Facebook"
import LinkedIn from "../../SocialButtons/LinkedIn/LinkedIn"
import Instagram from "../../SocialButtons/Instagram/Instagram"

import "./desktopHeader.scss"

const DesktopHeader = ({ onClickMenuItems, menuIsOpen, handleMenuOpen }) => {
  const { pathname } = useLocation()
  const [visibleLogo, setVisibleLogo] = React.useState(false)
  const [blackLogo, setBlackLogo] = React.useState(false)

  React.useEffect(() => {
    const header = document.querySelector("#header")
    menuIsOpen
      ? header.classList.add("openMenuPopup")
      : header.classList.remove("openMenuPopup")
  }, [menuIsOpen]);
  //
  // React.useEffect(() => {
  //   const logo = document.querySelector("#logo")
  //
  //   if (pathname !== "/") {
  //     setVisibleLogo(true)
  //
  //     document.body.classList.remove("loading")
  //     document.body.classList.add("loaded")
  //   } else {
  //     const homePageFirstScreen = document.querySelector(".firstScreen")
  //     const optionsHPFS = { threshold: 0.3 }
  //     const stickyHeader = (entries) => {
  //       entries.forEach((entry) => {
  //         if (!entry.isIntersecting) {
  //           logo.classList.add("visibleLogo")
  //           setBlackLogo(false)
  //         } else {
  //           logo.classList.remove("visibleLogo")
  //           setBlackLogo(true)
  //         }
  //       })
  //     }
  //     const observerHPFS = new IntersectionObserver(stickyHeader, optionsHPFS)
  //     observerHPFS.observe(homePageFirstScreen)
  //     return () => {
  //       observerHPFS.disconnect()
  //     }
  //   }
  // }, [pathname])

  return (
    <header id="header">
      <div className="wrapper">
        <nav>
          <MyLink
            href={LINKS.main}
            isExternal newWindow={false}
            className="logo visibleLogo header-text"
            id="logo"
          >
            <img
              itemProp="image"
              src={menuIsOpen || blackLogo ? logoBlack : logoWhite}
              alt="ScrambleLogo"
            />
          </MyLink>

          <ul className="navListCenter">
            {/*<li className="active">*/}
            {/*  <MyLink*/}
            {/*    href={LINKS.forInvestors}*/}
            {/*    isExternal newWindow={false}*/}
            {/*    className={*/}
            {/*      menuIsOpen*/}
            {/*        ? "header-text text-black"*/}
            {/*        : "header-text text-white"*/}
            {/*    }*/}
            {/*  >*/}
            {/*    For investors*/}
            {/*  </MyLink>*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*  <MyLink*/}
            {/*    href={LINKS.forFounders}*/}
            {/*    isExternal newWindow={false}*/}
            {/*    className={*/}
            {/*      menuIsOpen*/}
            {/*        ? "header-text text-black"*/}
            {/*        : "header-text text-white"*/}
            {/*    }*/}
            {/*  >*/}
            {/*    For startups*/}
            {/*  </MyLink>*/}
            {/*</li>*/}
          </ul>
          <div className="navListRight">
            <MyLink
              href={LINKS.signIn}
              isExternal newWindow={false}
              className="signInBtn"
            >
              <p
                className={
                  menuIsOpen
                    ? "header-text text-black"
                    : "header-text text-white"
                }
              >
                Sign in
              </p>
            </MyLink>
            <MyLink
              href={LINKS.getStarted}
              isExternal newWindow={false}
              className={
                menuIsOpen
                  ? "headerGetStarted header-text header-black-button"
                  : "headerGetStarted header-text header-yellow-button"
              }
            >
              Get started
            </MyLink>
            <button
              type="button"
              className="dots-button"
              onClick={handleMenuOpen}
            >
              {menuIsOpen ? (
                <div className="dots header-text">
                  <img src={dotBlack} alt="dotBlack" />
                  <img src={dotBlack} alt="dotBlack" />
                  <img src={dotBlack} alt="dotBlack" />
                </div>
              ) : (
                <div className="dots header-text">
                  <img src={dotWhite} alt="dotWhite" />
                  <img src={dotWhite} alt="dotWhite" />
                  <img src={dotWhite} alt="dotWhite" />
                </div>
              )}
            </button>
          </div>
        </nav>
        {menuIsOpen && (
          <div className="openMenu">
            <ul className="openMenuList">
              <li onClick={handleMenuOpen}>
                <img src={dotBlack} alt="dotBlack" />
                <MyLink
                  href={LINKS.main}
                  isExternal newWindow={false}
                  className="h1 nonColor"
                >
                  Main
                </MyLink>
              </li>
              <li onClick={handleMenuOpen}>
                <img src={dotBlack} alt="dotBlack" />
                <MyLink
                  href={LINKS.roundsHistory}
                  className="h1 nonColor"
                  isExternal
                  newWindow
                >
                  Rounds History
                </MyLink>
              </li>
              <li onClick={handleMenuOpen}>
                <img src={dotBlack} alt="dotBlack" />
                <MyLink
                  href={LINKS.root}
                  className="h1 nonColor"
                  onClick={onClickMenuItems}
                >
                  Blog
                </MyLink>
              </li>
              <li onClick={handleMenuOpen}>
                <img src={dotBlack} alt="dotBlack" />
                <MyLink
                  href={LINKS.affiliateProgramme}
                  isExternal newWindow={false}
                  className="h1 nonColor"
                >
                  Affiliate Program
                </MyLink>
              </li>
              <li onClick={handleMenuOpen}>
                <img src={dotBlack} alt="dotBlack" />
                <MyLink
                  href={LINKS.contactUs}
                  isExternal newWindow={false}
                  className="h1 nonColor"
                >
                  Contact us
                </MyLink>
              </li>
            </ul>
            <div className="openMenuRightPart">
              <MyLink
                href={`mailto:${CONTACT_EMAIL}`}
                isExternal newWindow={false}
                className="email body-text nonColor"
              >
                {CONTACT_EMAIL}
              </MyLink>
              <Facebook />
              <LinkedIn />
              <Instagram />
            </div>
          </div>
        )}
      </div>
    </header>
  )
}

export default DesktopHeader
