const ENVIRONMENT = process.env.REACT_APP_ENV || 'production';
const DEV_API_URL = process.env.REACT_APP_DEV_API_URL || 'http://localhost:8000';
const FACEBOOK_LINK = process.env.REACT_APP_FACEBOOK_LINK || '';
const LINKEDIN_LINK = process.env.REACT_APP_LINKEDIN_LINK || '';
const TWITTER_LINK = process.env.REACT_APP_TWITTER_LINK || '';
const INSTAGRAM_LINK = process.env.REACT_APP_INSTAGRAM_LINK || '';
const PARENT_DOMAIN = process.env.REACT_APP_PARENT_DOMAIN || '';
const FOUNDER_PROMO_DOMAIN = process.env.REACT_APP_FOUNDER_PROMO_DOMAIN || '';
const INVESTOR_PROMO_DOMAIN = process.env.REACT_APP_INVESTOR_PROMO_DOMAIN || '';
const INVESTOR_APP_DOMAIN = process.env.REACT_APP_INVESTOR_APP_DOMAIN || '';
const FOUNDER_APP_DOMAIN = process.env.REACT_APP_FOUNDER_APP_DOMAIN || '';
const API_URL = process.env.REACT_APP_API_URL || '';
const PREFIX = '/api/';

const getApiURL = () => (ENVIRONMENT === 'development' ? DEV_API_URL : API_URL);

const config = {
  facebookLink: FACEBOOK_LINK,
  linkedinLink: LINKEDIN_LINK,
  twitterLink: TWITTER_LINK,
  instagramLink: INSTAGRAM_LINK,
  baseURL: window.location.origin.toString(),
  domain: window.location.origin.toString(),
  parentDomain: PARENT_DOMAIN,
  investorApp: INVESTOR_APP_DOMAIN,
  founderApp: FOUNDER_APP_DOMAIN,
  foundersPromo: FOUNDER_PROMO_DOMAIN,
  investorsPromo: INVESTOR_PROMO_DOMAIN,
  apiURL: getApiURL() + PREFIX,
  environment: ENVIRONMENT,
};

export default config;