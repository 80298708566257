import React from "react"
import classnames from "classnames"

import {CONTACT_EMAIL, LINKS} from "../../../helpers/constants";
import {MyLink} from "../../../components/MyLink";

import logoWhite from "../../logoWhite.svg"
import mobDotWhite from "../../mobDotWhite.svg"
import mobDotBlack from "../../mobDotBlack.svg"
import Facebook from "../../SocialButtons/Facebook/Facebook"
import LinkedIn from "../../SocialButtons/LinkedIn/LinkedIn"
import Instagram from "../../SocialButtons/Instagram/Instagram"

import "./mobHeader.scss"

const MobHeader = ({ menuIsOpen, handleMenuOpen }) => {
  React.useEffect(() => {
    const body = document.querySelector("body")
    const mobHeader = document.querySelector("#mobHeader")
    menuIsOpen
      ? body.classList.add("bodyOverflowHidden")
      : body.classList.remove("bodyOverflowHidden")

    menuIsOpen
      ? mobHeader.classList.add("openMobMenuPopup")
      : mobHeader.classList.remove("openMobMenuPopup")
  }, [menuIsOpen])

  return (
    <header className="mobHeader" id="mobHeader">
      <div className="wrapper" style={{height: "100%"}}>
        {!menuIsOpen && (
          <nav>
            <MyLink
              href={LINKS.forInvestors}
              className="mobLogo header-text"
              isExternal newWindow={false}
            >
              <img itemProp="image" src={logoWhite} alt="ScrambleLogo" />
            </MyLink>
            <button
              type="button"
              className="mobDots-button"
              onClick={handleMenuOpen}
            >
              <div className="mobDots header-text">
                <img src={mobDotWhite} alt="mobDotWhite" />
                <img src={mobDotWhite} alt="mobDotWhite" />
                <img src={mobDotWhite} alt="mobDotWhite" />
              </div>
            </button>
          </nav>
        )}

        {menuIsOpen && (
          <div className="openMobMenu">
            <div className="header">
              <ul className="headerMenu">
                <li className="active">
                  <MyLink href={LINKS.forInvestors} isExternal newWindow={false} className="header-text text-black">
                    For investors
                  </MyLink>
                </li>
                <li>
                  <MyLink
                    href={LINKS.forFounders}
                    className="header-text text-black"
                    isExternal newWindow={false}
                  >
                    For startups
                  </MyLink>
                </li>
              </ul>
              <button
                type="button"
                className="mobDots-button"
                onClick={handleMenuOpen}
                style={{opacity: 0}}
              >
                <div className="mobDots header-text">
                  <img src={mobDotBlack} alt="mobDotBlack" />
                  <img src={mobDotBlack} alt="mobDotBlack" />
                  <img src={mobDotBlack} alt="mobDotBlack" />
                </div>
              </button>
            </div>
            <ul className="openMobMenuList">
              <li onClick={handleMenuOpen}>
                <MyLink
                  href={LINKS.main}
                  className="h1 text-black"
                  isExternal newWindow={false}
                >
                  Main
                </MyLink>
              </li>
              <li onClick={handleMenuOpen}>
                <MyLink
                  href={LINKS.roundsHistory}
                  isExternal newWindow={false}
                  className="h1 text-black"
                >
                  Rounds History
                </MyLink>
              </li>
              <li onClick={handleMenuOpen}>
                <MyLink
                  href={LINKS.root}
                  className="h1 text-black"
                >
                  Blog
                </MyLink>
              </li>
              <li onClick={handleMenuOpen}>
                <MyLink
                  href={LINKS.affiliateProgramme}
                  className="h1 text-black"
                  isExternal newWindow={false}
                >
                  Affiliate Program
                </MyLink>
              </li>
              <li onClick={handleMenuOpen}>
                <MyLink
                  href={LINKS.contactUs}
                  className="h1 text-black"
                  isExternal newWindow={false}
                >
                  Contact us
                </MyLink>
              </li>
            </ul>
            <div className={classnames("buttonsBlock", menuIsOpen && "open")}>
              <MyLink
                href={LINKS.signIn}
                className="mob-header-empty-button header-text"
                isExternal newWindow={false}
              >
                Sign in
              </MyLink>
              <MyLink
                href={LINKS.getStarted}
                className="header-text mob-header-black-button"
                isExternal newWindow={false}
              >
                Get started
              </MyLink>
            </div>
            <div className="footer">
              <MyLink
                className="email body-text text-black"
                href={`mailto:${CONTACT_EMAIL}`}
                isExternal
              >
                {CONTACT_EMAIL}
              </MyLink>
              <div className="socialButtonsGroup">
                <Facebook />
                <LinkedIn />
                <Instagram />
              </div>
            </div>
          </div>
        )}
      </div>
    </header>
  )
}

export default MobHeader
